import gql from 'graphql-tag'

const enableUser = async (client, userId, enabled) => {
  const results = await client.mutate({
    mutation: gql`mutation EnableUser($input: EnableUserInput) {
      EnableUser(input: $input) {
        id
      }
    }`,
    variables: {input: {id: userId, enabled}}
  })
  return results
}

export default enableUser;
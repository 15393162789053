import _ from 'lodash'
import React from 'react'
import { Icon, Table } from 'semantic-ui-react'
import Cvss from '../../../../components/labels/Cvss'
import Typography from '@material-ui/core/Typography'

class FindingIcon extends React.Component {
  render() {
    const { findingType } = this.props
    let icon
    switch (findingType) {
      case 'FindingVulnerableFunctionCall':
        icon = 'code'
        break
      case 'FindingGeneric':
        icon = 'eye'
        break
      case 'FindingVulnerableVersion':
        icon = 'code branch'
        break
      case 'FindingOsConfiguration':
        icon = 'wrench'
        break
      case 'FindingCryptographicKey':
        icon = 'key'
        break
      default:
        icon = 'question'
        break
    }
    return (
      <Icon inverted bordered name={icon} />
    )
  }
}

class FindingsTable extends React.Component {

  constructor(props) {
    super()
    this.state = {
      column: null,
      data: _.sortBy(props.rows, 'scoreCvss').reverse(),
      direction: null,
    }
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state
    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: 'ascending',
      })

      return
    }
    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  render() {
    const { column, direction } = this.state
    const { selectedFindingId, selectFinding, titleFilter, typeFilter } = this.props
    let rows = this.state.data
    if (titleFilter !== undefined && titleFilter !== '') {
      rows = rows.filter(f => (f.title.toLowerCase().includes(titleFilter.toLowerCase())))
    }
    if (typeFilter !== undefined && typeFilter.length !== 0) {
      rows = rows.filter(f => (typeFilter.includes(f.__typename)))
    }

    return (
      <Table sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell
              sorted={column === 'title' ? direction : null}
              onClick={this.handleSort('title')}
            >
              Title
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'scoreCvss' ? direction : null}
              onClick={this.handleSort('scoreCvss')}
            >
              Severity
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { rows.map(row => {
            const active = (selectedFindingId === row.id)
            let additionalTitleContext = null;
            if (row.__typename === 'FindingVulnerableVersion' && row.vulnerableEntity !== undefined && row.vulnerableEntity.length > 0) {
              additionalTitleContext = ` (v${row.vulnerableEntity[0].node.version})`
            }
            return (
              <Table.Row key={row.id} onClick={() => selectFinding(row.id)} active={active}>
                <Table.Cell collapsing>
                  <FindingIcon findingType={row.__typename} />
                </Table.Cell>
                <Table.Cell>
                  <Typography>{row.title}{additionalTitleContext}</Typography>
                </Table.Cell>
                <Table.Cell collapsing>
                  <Cvss score={row.scoreCvss} />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }
}

export default FindingsTable
import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { Icon, Segment } from 'semantic-ui-react'
import { Typography, Button } from '@material-ui/core'
import firebase from 'firebase/app'
import 'firebase/auth'
import pilotLogo from '../../../resources/img/pilot-blue.png'
import { TermsOfServiceModal } from '../../../routes/misc/TermsOfService'


const uiConfig = {
  signInFlow: 'redirect',
  signInSuccessUrl: '/',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      scopes: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email'
      ]
    },
    {
      provider: 'microsoft.com',
      iconUrl: '/ms_logo_19.png',
      providerName: 'Microsoft'
    },
    {
      provider: 'oidc.comcast-azuread-pilot',
      iconUrl: '/ms_logo_19.png',
      providerName: 'Azure AD'
    }
  ]
};

const mapErrorMessage = (message, errorUserEmail) => {
  if (message.includes('GraphQL error: undefined')) {
    // This case has been seen when the wrong login profile is used.
    // This error message has been coded in per https://gitlab.com/pilot-security/product-management/-/issues/123
    return `Login error for ${errorUserEmail}.`
  }
  else if (message.includes('GraphQL error:')) {
    return message.split('GraphQL error:')[1]
  }
  else if (message === 'Network error: Failed to fetch') {
    return 'Unable to reach server.'
  }
  return message
}

class FirebaseLogin extends React.Component {
  
  state = {
    error: null,
    errorUserEmail: null,
  }

  // Listen to the Firebase Auth state and set the local state.
  async componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        async (user) => {
          if (user !== null) {
            this.props.resolveUserProfile()
            .then((userProfile) => {
              if (userProfile !== null && userProfile !== undefined) {
                this.props.setUserProfile(userProfile)
              } else {
                this.setState({
                  error: {
                    message: 'Unable to parse user profile from server.'
                  }
                })
              }
            })
            .catch(error => {
              this.setState({error, errorUserEmail: user.email})
            })
          }
        }
    );
  }

  reset() {
    firebase.auth().signOut()
    this.props.setUserProfile(null)
    this.setState({error: null, errorUserEmail: null})
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const { userProfile } = this.props
    const { error, errorUserEmail } = this.state

    // error occured during login, display message
    if (error) {
      return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 50}}>
          <Segment style={{padding: 20, width: 600}}>
            <h2><Icon size='large' color='red' name='warning sign'/>Login Error</h2>
            <div style={{marginTop: 20, marginBottom: 20}}>{mapErrorMessage(error.message, errorUserEmail)}</div>
            <Button color='blue' onClick={() => {this.reset()}}>
              Retry Login
            </Button>
          </Segment>
        </div>
      )
    }

    // no user profile set, display login screen
    if (!userProfile) { 
      return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 50}}>
          <Segment>
            <img alt='' style={{width: 220, height: 'auto', marginBottom: 20}} src={pilotLogo} />
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/> 
            <Typography variant="subtitle2">
              By logging in, you agree to the <TermsOfServiceModal />.
            </Typography>
          </Segment>
        </div>
      )
    }
    
    // user profile loaded, display app
    return this.props.children
  }
}

export default FirebaseLogin

import React from 'react'
import gql from 'graphql-tag'
import { Divider } from '@material-ui/core'
import { Button, Icon, Input, Loader, Segment } from 'semantic-ui-react'
import { withGraphqlQuery } from '../../../graphql/core/connectors'
import { withRBAC } from '../../../components/core/access/RBAC'
import Page from '../../../components/core/layout/Page'
import createGroup from '../../../graphql/functions/createGroup'
import { Separator } from '../../../components/core/layout/Separator'
import DeleteGroup from '../../../components/DeleteGroup'
import deleteGroup from '../../../graphql/functions/deleteGroup'
import grantMembership from '../../../graphql/functions/grantMembership';
import revokeMembership from '../../../graphql/functions/revokeMembership';
import findUser from '../../../graphql/functions/findUser';

class GroupsTab extends React.Component {

  state = {
    name: "",
    selected: null,
    userName: ""
  }

  render() {
    const groups = this.props.data
    const { client, refetch } = this.props
    //const selectedGroup = groups.find(g => g.id === this.state.selected);
    return (
        <Page>
          <h2>Groups</h2>
          <Divider />
          <Separator />
          <div className='row' style={{gap: 10}}>
            <Input value={this.state.name} onChange={(_, {value}) => this.setState({name: value})} />
            <Button
              size='small'
              color='blue'
              content={<><Icon name='add'/> Group</>}
              onClick={() => {
                createGroup(client, this.state.name)
                .then(() => {
                  this.setState({name: ''})
                  refetch()
                })
              }}
            />
          </div>
          <div className='column' style={{gap: 20, marginTop: 10}}>
            {groups.map(group => {
              return <Group key={group.id} client={client} refetch={refetch} data={group} />
            })}
          </div>
        </Page>
    )
  }
}

// data
GroupsTab = withGraphqlQuery({
  query: gql`
    query {
      Group {
        id
        name
        users {
          node {
            id
            name
          }
        }
      }
    }
  `,
  onData: (data) => data.Group
})(GroupsTab)

// access control
GroupsTab = withRBAC('admin')(GroupsTab)

export default GroupsTab


class Group extends React.Component {
  
  state = { 
    open: false,
    newUser: '',
    loading: false,
  }

  render() {
    const { data, client, refetch } = this.props;
    const group = data;
    return (
      <div className='column' style={{width: 400}}>
        <Segment key={group.id} onClick={() => this.setState({open: !this.state.open})} >
          <div className='row' style={{gap: 5, width: '100%'}} >
            <Icon name={this.state.open ? 'angle double down' : 'angle double up'} />
            <div>{group.name}</div>
            <div style={{flex: 1}}/>
            <DeleteGroup data
              groupId={group.id} 
              onDelete={() => {
                deleteGroup(client, group.id)
                .catch(e => console.log(e))
                .then(_ => refetch())
              }}
            />
          </div>
        </Segment>
        {this.state.open ? (
          <div className='column' style={{gap: 5}}>
            <div className='row' style={{gap: 5}}>
              <Input style={{flex: 1}} value={this.state.newUser} onChange={(_, {value}) => this.setState({newUser: value})} />
              <Button 
                color='blue' 
                size='small'
                content={<><Icon name='add'/>User</>}
                onClick={async () => {
                  let result = await findUser(client, this.state.newUser);
                  if (result.data.User.length !== 1) {
                    return;
                  }
                  let user = result.data.User[0];
                  if (user) {
                    this.setState({loading: true})
                    grantMembership(client, group.id, user.id)
                    .catch(e => {
                      this.setState({loading: false})
                      console.log(e)
                    })
                    .then(_ => {
                      this.setState({newUser: ''})
                      refetch()
                      this.setState({loading: false})
                    })
                  } else {
                    console.error('user not found')
                  }
                }}
              />
              <Loader inline active={this.state.loading} />
            </div>
            {group.users.map(r => r.node).map(user => {
              return <User key={user.id} user={user} group={group} refetch={refetch} client={client} />
            })}
          </div>
        ) : null}
      </div>
    )
  }
}

class User extends React.Component {

  state = {
    loading: false
  }

  render() {
    const { user, group, client, refetch } = this.props;
    return (
      <div className='row' style={{gap: 10}}>
        <div style={{border: 'solid 1px #D0D0D0', borderRadius: 5, padding: 10, flex: 1}}>
          {user.name}
        </div>
        <Button
          size='tiny'
          color='red'
          content='Remove'
          onClick={() => {
              this.setState({loading: true})
              revokeMembership(client, group.id, user.id)
              .catch(e => {
                this.setState({loading: false})
              })
              .then(_ => {
                refetch()
                this.setState({loading: false})
              })

          }}
        />
        <Loader inline active={this.state.loading} />
      </div>
    )
  }
}
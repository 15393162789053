import { Container, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import PageSection from '../../../../components/core/page/PageSection';
import SimpleTable from '../../../../components/core/tables/SimpleTable';
import DataNotReady from '../../../../components/messages/DataNotReady';
import PrintableDivider from '../../../../components/misc/PrintableDivider';
import { withGraphqlQuery } from '../../../../graphql/core/connectors';
import { computeFirmwarePollStopCheckForSubmissionSingle } from '../../../../utils/firmware';
import { formatBytes } from '../../../../utils/format';
import FindingsSummary from '../summary/findings/FindingsSummary';
import FindingsTable from './FindingsTable';
import FindingCryptographicKey from './types/cryptography';
import FindingVulnerableFunctionCall from './types/functioncalls';
import FindingGeneric from './types/generic';
import FindingOsConfiguration from './types/osconfig';
import UnknownFinding from './types/unknown';
import FindingVulnerableVersion from './types/version';


export class PrintableCpeLinks extends React.Component {
  render() {
    const uniqueCpes = {};
    for (let ve of this.props.vulnerableEntities) {
      const cpeForUrl = ve.cpe.replace(/[:*]+$/g, '').replace(':', '%3a');
      uniqueCpes[ve.cpe] = 'https://nvd.nist.gov/vuln/search/results?form_type=Advanced&cves=on&cpe_version=' + cpeForUrl
    }
    //https://nvd.nist.gov/vuln/search/results?form_type=Advanced&cves=on&cpe_version=cpe%3a%2fa%3aopenssl%3aopenssl%3a1.0.2t
    // NOTE: Table is likely only ever going to have one row per finding given the way we group things.
    return (
      <React.Fragment>
        Visit the NIST website to see the most up-to-date list of CVEs correlated to the identified software version:
        <List>
          { Object.entries(uniqueCpes).map(entries => {
            return (<ListItem component="a" href={entries[1]} key={entries[0]} target="_new">
              <ListItemText primary={entries[1]} />
            </ListItem>)
          }) }
        </List>
      </React.Fragment>
    )
  }
}

class FindingComponent extends React.Component {
  getFindingComponent(__typename) {
    switch (__typename) {
      case 'FindingGeneric':
        return FindingGeneric
      case 'FindingOsConfiguration':
        return FindingOsConfiguration
      case 'FindingCryptographicKey':
        return FindingCryptographicKey
      case 'FindingVulnerableFunctionCall':
        return FindingVulnerableFunctionCall
      case 'FindingVulnerableVersion':
        return FindingVulnerableVersion
      default: 
        return UnknownFinding
    }
  }

  render() {
    const { finding } = this.props
    const ActualFindingComponent = this.getFindingComponent(finding.__typename)
    return (
      <div style={{breakAfter: 'page'}}>
        <ActualFindingComponent finding={finding} id={finding.id} printStyle={true} />
        <PrintableDivider />
      </div>
    )
  }
}


class FindingsPrintable extends React.Component {
  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }
  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  render() {
    const { data } = this.props
    const submission = data
    const image = submission.image.node
    let rows = image.findings.map(r => r.node)
    if (rows.length === 0) {
      return <DataNotReady />
    }
    // Choose the sort function:
    const sortFuncArray = [
      (x) => {return -x.scoreCvss},
      (x) => {return x.title}
    ];
    rows = _.sortBy(rows, sortFuncArray)
    // NOTE: You must wrap in an element like div, warping in React.Fragment breaks the print handler.
    return (
      <div style={{margin: '0cm'}}>
        <Typography variant='h2'>{submission.productName}</Typography>
        <SimpleTable 
          order={['Version', 'Filename', 'Size', 'Uploaded By', 'Uploaded On', 'Export Time']}
          fields={{
            'Version': submission.productVersion,
            'Uploaded By': (submission.user.node === null) ? '' : submission.user.node.name,
            'Uploaded On': `${moment(submission.dateSubmitted).format('lll')} (${moment(submission.dateSubmitted).fromNow()})`,
            'Filename': image.inputFilename,
            'Size': formatBytes(parseInt(image.size)),
            'Export Time': `Exported ${(new Date()).toUTCString()}.`
          }}
        />
        <Container>
          To view full information on vulnerabilities and explore this firmware, log into <a href={window.location.href}>{window.location.href}</a>.
        </Container>
        <PrintableDivider />
        <PageSection title='Findings Overview'>
          <FindingsSummary data={submission} wrapInPageSection={false} />
        </PageSection>
        <PrintableDivider />
        <span />
        <PageSection title='Findings Summary' printableBreakBefore={true}>
          <FindingsTable rows={rows} />
        </PageSection>
        <PrintableDivider />
        <span />
        <PageSection title='Findings Detail' printableBreakBefore={true}>
          { rows.map((r) => <FindingComponent finding={r} key={r.id} />) }
        </PageSection>
      </div>
    )
  }
}

// data
FindingsPrintable = withGraphqlQuery({
  query: gql`
    query Submission($input: SubmissionMatchInput) {
      Submission(input: $input) {
        id
        productName
        productVersion
        dateSubmitted
        errors
        warnings
        user {
          id
          node {
            id
            name
          }
        }
        image {
          id
          node {
            id
            status
            size
            inputFilename
            sha256
            filesystems {
              id
              offset
              extraction
              node {
                id
                name
                fstype
              }
            }
            otherContents {
              id
              offset
              extraction
              node {
                id
                name
                desc
              }
            }
            kernels {
              id
              offset
              extraction
              node {
                id
                name
                desc
              }
            }
            cryptographicData {
              id
            }
            encryptedContents {
              id
            }
            baremetalContents {
              id
            }
            findings {
              id 
              node {
                ... on FindingVulnerableFunctionCall {
                  id
                  title
                  scoreCvss
                }
                ... on FindingVulnerableVersion {
                  id
                  title
                  scoreCvss
                }
                ... on FindingOsConfiguration {
                  id
                  title
                  scoreCvss
                }
                ... on FindingGeneric {
                  id
                  title
                  scoreCvss
                }
                ... on FindingCryptographicKey {
                  id
                  title
                  scoreCvss
                }
              }
            }
          }
        }
      }
    } 
  `,
  variables: (props) => ({
    input: {id: props.submissionId}
  }),
  pollStopCheck: computeFirmwarePollStopCheckForSubmissionSingle,
  pollInterval: 5000,
  onData: (data) => data.Submission[0]
})(FindingsPrintable)

export default FindingsPrintable

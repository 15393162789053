import React from 'react'
import SummaryTile from '../../../../../components/tiles/SummaryTile'
import DataNotReady from '../../../../../components/messages/DataNotReady'
import DataNoResults from '../../../../../components/messages/DataNoResults'
import { isAnalysisFinished } from '../../../../../utils/firmware'

const FINDING_VULNERABLE_FUNCTION_CALL = 'FindingVulnerableFunctionCall'
const FINDING_VULNERABLE_VERSION = 'FindingVulnerableVersion'
const FINDING_CRYPTOGRAPHIC_KEY = 'FindingCryptographicKey'
const FINDING_OS_CONFIGURATION = 'FindingOsConfiguration'
const FINDING_INFO_DISCLOSURE = 'FindingGeneric'

const Finding = (props) => {
  const { type, count } = props.data
  switch (type) {
    case FINDING_VULNERABLE_FUNCTION_CALL:
      return <SummaryTile icon='code' text='Vulnerable Function Calls' count={count} />
    case FINDING_VULNERABLE_VERSION:
      return <SummaryTile icon='code branch' text='Vulnerable Software Versions' count={count} />
    case FINDING_CRYPTOGRAPHIC_KEY:
      return <SummaryTile icon='key' text='Cryptography' count={count} />
    case FINDING_OS_CONFIGURATION:
      return <SummaryTile icon='configure' text='Unsafe OS Configurations' count={count} />
    case FINDING_INFO_DISCLOSURE:
      return <SummaryTile icon='eye' text='Information Disclosure' count={count} />
    default:
      return ''
  }
}

const findingTypes = [
  FINDING_VULNERABLE_FUNCTION_CALL,
  FINDING_VULNERABLE_VERSION,
  FINDING_CRYPTOGRAPHIC_KEY,
  FINDING_OS_CONFIGURATION,
  FINDING_INFO_DISCLOSURE
]

class FindingsSummary extends React.Component {
  render() {
    const submission = this.props.data
    const status = submission.image.node.status
    const findings = submission.image.node.findings

    const findingsResults = findingTypes.map((findingType) => {
      return {
        type: findingType,
        count: findings.filter(t => t.node.__typename === findingType).length.toString()
      }
    })
    return (
      <React.Fragment>
        { (findings.length === 0) ? (
          isAnalysisFinished(status) ? (
            <DataNoResults msg='No security findings were found in this firmware, contact support.' />
          ) : (
            <DataNotReady />
          )
        ) : (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            { findingsResults.filter(f=> (f.count > 0)).map(f => <Finding key={f.type} data={f} /> )}
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default FindingsSummary
